import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import i18n from './i18n'
/*import VueScrollactive from 'vue-scrollactive'
import VueScrollTo from 'vue-scrollto'*/
import * as VueGoogleMaps from 'vue2-google-maps'
import VueModal from 'vue-js-modal'

import './assets/scss/App.scss'
import Welcome from '@/views/Welcome.vue'
import Services from '@/views/Services.vue'
import Team from '@/views/Team.vue'
import PracticeRooms from '@/views/PracticeRooms.vue'
import HowToFindUs from '@/views/HowToFindUs.vue'

Vue.config.productionTip = false

Vue.use(VueRouter)
/*Vue.use(VueScrollactive)
Vue.use(VueScrollTo)*/
Vue.use(VueModal, { dialog: true })
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCVH7BvziNmeJ6-G7z13nUrca86BauXPfQ'
    // libraries: 'places' // necessary for places input
  }
});

const routes = [
  { path: '/', component: Welcome, alias: '/willkommen' },
  { path: '/leistungen', component: Services },
  { path: '/team', component: Team },
  { path: '/praxisraeume', component: PracticeRooms },
  { path: '/anfahrt-kontakt', component: HowToFindUs }
]

const router = new VueRouter({ routes, mode: 'history' })

new Vue({
  i18n,
  router,
  render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}).$mount('#app');

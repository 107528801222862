















































import Vue from 'vue'
import ViewBase from './ViewBase.vue'
import ServiceList from '../components/sercvices/ServiceList.vue'

export default Vue.extend({
  name: 'services',
  extends: ViewBase,
  components: { ServiceList }
});

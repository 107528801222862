








































































































import Vue from 'vue'
import ViewBase from './ViewBase.vue'

export default Vue.extend({
  name: 'how-to-find-us',
  extends: ViewBase,
  computed: {
    mailHref () {
      return 'mailto:' + (this as any).$t('howToFindUs.address.mail')
    },
    phoneHref () {
      return 'tel:' + (this as any).$t('howToFindUs.address.phoneNormalized')
    },
    faxHref () {
      return 'fax:' + (this as any).$t('howToFindUs.address.faxNormalized')
    }
  }
});























import Vue from 'vue'
import { sanitizeHash } from '@/utils/sanitize-hash'

export default Vue.extend({
  name: 'navigation',
  data: () => ({
    menuVisible: false,
    isScrolled: true
  }),
  computed: {
    links (): string[] {
      return Object.values(this.$t('pages'))
    },
    scrollOffset (): number {
      return window.innerWidth >= 768 ? 180 : 80
    }
  },
  methods: {
    onScroll () {
      /*this.isScrolled =
        (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20)*/
    },
    sanitizeHash (value: string): string {
      return sanitizeHash(value)
    },
    showMenu (): void {
      this.menuVisible = true
      document.body.addEventListener('click', this.closeMenu)
    },
    closeMenu (): void {
      document.body.removeEventListener('click', this.closeMenu)
      this.menuVisible = false
    }
  },
  mounted (): void {
    /*window.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onScroll)*/
  }
})

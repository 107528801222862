












































































































































import Vue from 'vue'
import SiteHeader from './components/site-header/SiteHeader.vue'
import SiteFooter from './components/site-footer/SiteFooter.vue'

import CookieLaw from 'vue-cookie-law';

import Welcome from './views/Welcome.vue';
import Services from './views/Services.vue';
import Team from './views/Team.vue';
import PracticeRooms from './views/PracticeRooms.vue';
import HowToFindUs from './views/HowToFindUs.vue';

export default Vue.extend({
  components: {
    SiteHeader,
    Welcome,
    Services,
    Team,
    PracticeRooms,
    HowToFindUs,
    SiteFooter,
    CookieLaw
  }
})

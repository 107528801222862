































import Vue from 'vue'
import ViewBase from './ViewBase.vue'

export default Vue.extend({
  name: 'welcome',
  extends: ViewBase,
  components: {

  }
});

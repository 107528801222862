









import Vue from 'vue'
import shortHash from 'short-hash'

export default Vue.extend({
  name: 'navigation',
  data: () => ({
    expanded: false
  }),
  props: {
    area: {
      type: String,
      required: true
    }
  },
  computed: {
    list (): string[] {
      const items: any[] = this.$t(`services.${this.area}.list`) as any

      return this.expanded ? items : items.slice(0, 4)
    }
  },
  methods: {
    toggleList () {
      this.expanded = !this.expanded

      if (!this.expanded) {
        (this as any).$scrollTo(`#services-${this.area}`, 500, {
          offset: -60,
        })
      }
    },
    hash (val: string): string {
      return shortHash(val)
    }
  }
})

















import Vue from 'vue'
import ViewBase from './ViewBase.vue'
import { sanitizeHash } from '@/utils/sanitize-hash'

interface Employee {
  name: string,
  image: string,
  position: string
}

export default Vue.extend({
  name: 'team',
  extends: ViewBase,
  computed: {
    employees (): Employee[] {
      const rawData = this.$t('team.employees') as any
      const result: Employee[] = []
      for (const name in rawData) {
        if (!rawData.hasOwnProperty(name)) { continue }
        result.push({
          name,
          image: require('../assets/images/team/' + sanitizeHash(name) + '.jpg'),
          position: rawData[name]
        })
      }
      return result
    }
  }
});

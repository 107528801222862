<template>
    <footer>
        <div class="container-full">
            <div class="grid">
                <div class="col-12 footer-links">
                    <a @click="showImprint()">Impressum</a>
                    <a @click="showPrivacy()">Datenschutzerklärung</a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    name: 'SiteFooter',
    methods: {
      showImprint () {
        this.$modal.show('imprint');
      },
      showPrivacy () {
        this.$modal.show('privacy');
      }
    }
  })
</script>

<style scoped lang="scss">
    footer {
        flex: 0 0 auto;
        z-index: 6;
        width: 100vw;
        font-family: 'Montserrat', Helvetica, Arial, sans-serif;
        background-color: #fff;
        border-top: 1px solid rgba(#13f1fc, 0.3);

        a {
            color: #333;
            position: relative;
            font-size: rem(14);
            padding: 5px 0;
            top: -3px;
            display: inline-block;
            margin-right: rem(30);

            &:last-of-type {
                margin-right: 0;
            }

            &:after {
                position: absolute;
                left: 0;
                bottom: 0;
                height: 2px;
                width: 100%;
                background-color: #818181;
                content: '';
                display: block;
                transform: translateZ(0);
                transition: transform 300ms ease-out;
            }

            &:hover:after {
                transform: translateY(2px) translateZ(0);
            }
        }

        .footer-links {
            text-align: center;

            @media (min-width: $reflex-sm) {
                text-align: right;
            }
        }
    }
</style>

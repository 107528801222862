function replaceUmlauts (value: string): string {
  let result = value
  result = result.replace(/ä/g, 'ae');
  result = result.replace(/ö/g, 'oe');
  result = result.replace(/ü/g, 'ue');
  result = result.replace(/ß/g, 'ss');
  result = result.replace(/&/g, '-');
  return result
}

export function sanitizeHash (value: string): string {
  let result = value.toLowerCase()
  result = replaceUmlauts(result)

  result = result.replace(/ /g, '-');
  result = result.replace(/--+/g, '-');


  return result
}

<template>
    <header>
        <info-box></info-box>
        <navigation></navigation>
    </header>
</template>

<script>
  import Vue from 'vue'
  import Navigation from './Navigation'
  import InfoBox from './InfoBox'

  export default Vue.extend({
    name: 'SiteHeader',
    components: {
      Navigation,
      InfoBox
    }
  })
</script>

<style scoped lang="scss">
    header {
        z-index: 99;
        left: 0;
        top: 0;
        width: 100vw;
        font-family: 'Montserrat', Helvetica, Arial, sans-serif;
        flex: 0 1 auto;
    }
</style>

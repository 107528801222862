
import Vue from 'vue'
import { sanitizeHash } from '@/utils/sanitize-hash'

export default Vue.extend({
  name: 'view-base',
  computed: {
    title (): string {
      const name = this.$options.name
      return this.$t(`pages.${name}`) as string
    },
    hash (): string {
      return sanitizeHash(this.title)
    }
  },
  components: {

  },
});
